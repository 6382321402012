import router from '@/router/router';
import { JWTService } from '@/shared/services/jwt';
import ClientFactory from '../../factories/client';

const config = {
  baseURL: process.env.VUE_APP_ABDABaseURLCore,
  headers: { 'Content-Type': 'application/json' },
};

const ABDAClient = ClientFactory.New(config);

ABDAClient.interceptors.request.use((request) => {
  const token = JWTService.GetToken();

  /* eslint-disable-next-line */
  if (token) request.headers.Authorization = `Bearer ${JWTService.GetToken()}`;

  return request;
}, error => Promise.reject(error));

ABDAClient.interceptors.response.use(response => response, (error) => {
  if (error.response.status.toString().match(/4\d[13]/)) {
    JWTService.DestroyToken();
    router.replace({ name: 'SignIn' });
  }
  return Promise.reject(error);
});

export default ABDAClient;
