import AccessManager from '../../clients/abdaAccessManager'

const resourceName = 'permission'

const GetAll = () => AccessManager.get(resourceName)
const Create = data => AccessManager.post(resourceName, data)


const PermissionRepository = {
  GetAll,
  Create
}

export default PermissionRepository
