const ModalityRoutes = [
  {
    path: '/cadastros/modalidades',
    meta: { title: 'Modalidades' },
    name: 'Modality',
    component: () => import('@/views/configuration/modality/list/ModalityList.vue')
  },
  {
    path: '/cadastros/modalidades/novo',
    meta: { title: 'Nova Modalidade' },
    name: 'NewModality',
    component: () => import('@/views/configuration/modality/create/ModalityCreate.vue')
  },
  {
    path: '/cadastros/modalidades/:id',
    meta: { title: 'Editar Modalidade' },
    name: 'EditModality',
    component: () => import('@/views/configuration/modality/edit/ModalityEdit.vue')
  }
]

export default ModalityRoutes
