import DeficiencyTypeRepository from '@/shared/http/repositories/socialProject/deficiency-type'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import * as mutation from './mutationTypes'
import { actionTypes as action } from './actionTypes'

export const actions = {
  [action.FETCH_DEFICIENCY_TYPES]: async ({ commit }) => {
    try {
      loading.push()

      const res = await DeficiencyTypeRepository.GetAll()
      if (!IsSuccessCode(res)) return Promise.reject()

      commit(mutation.SET_DEFICIENCY_TYPES, res.data.data)
      loading.pop()
      return Promise.resolve()
    } catch (error) {
      loading.pop()
      toast.error('Erro ao pegar os tipos de deficiência', 'ERRO')
    }
  }
}
