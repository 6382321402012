import CityRepository from '@/shared/http/repositories/core/city'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import * as mutation from './mutationTypes'
import { actionTypes as action } from './actionTypes'

export const actions = {
  [action.FETCH_CITIES]: async({ commit }, stateId) => {
    try {
      loading.push()

      const res = await CityRepository.GetByStateId(stateId)
      if (!IsSuccessCode(res)) return Promise.reject()

      commit(mutation.SET_CITIES, res.data.result)
      loading.pop()
      return Promise.resolve()
    } catch (error) {
      loading.pop()
      toast.error('Erro ao carregar cidades', 'ERRO')
    }
  }
}
