const QuestionsRoutes = [
  {
    path: '/cadastros/questoes/:anamneseid',
    meta: { title: 'Questões' },
    name: 'Questions',
    component: () => import('@/views/configuration/questions/list/QuestionsList.vue'),
  },
  {
    path: '/cadastros/questoes/:anamneseid/questao/novo',
    meta: { title: 'Nova Questão' },
    name: 'NewQuestions',
    component: () => import('@/views/configuration/questions/create/QuestionsCreate.vue')
  },
  {
    path: '/cadastros/questoes/:anamneseid/questao/:id',
    meta: { title: 'Editar Questão' },
    name: 'EditQuestions',
    component: () => import('@/views/configuration/questions/edit/QuestionsEdit.vue')
  }
]

export default QuestionsRoutes
