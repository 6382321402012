const SmsLogRoutes = [
  {
    path: '/sms-logs',
    meta: { title: 'SmsLogs' },
    name: 'SmsLogs',
    component: () => import('@/views/admin/smslogs/smsLogs.vue')
  }
]

export default SmsLogRoutes
