/**
 * @function getNamespacedBy
 * @description Retorna os valores de um objeto com um namespace.
 * @param {string} namespace O namespace para ser inserido.
 * @param {Object} obj O objeto para inserir namespace nos valores.
 * @returns {Object}
 * @example { auth: 'login'} retorna { auth: 'namespace/login' }
 */
export const getNamespacedBy = (namespace, obj) => {
  const namespacedActions = {};
  Object.entries(obj).forEach((entry) => {
    namespacedActions[entry[0]] = `${namespace}/${entry[1]}`;
  });
  return namespacedActions;
};

export const StoreUtils = {
  getNamespacedBy,
};

export default StoreUtils;
