import { merge } from 'lodash';
import AccessManager from '../../clients/abdaAccessManager';

const resourceName = 'account';

const GetFromAccessManager = id => AccessManager.get(`${resourceName}/${id}`);

// AccessManager
/**
 * Busca contas do sistema.
 * @param {object} criteria Critério de busca de contas.
 * @param {object} pagination Configurações de paginação.
 * @return {Promise}
 */
const GetAll = (criteria, pagination) => AccessManager.get(`${resourceName}`, {
  params: merge(criteria, pagination),
});

const GetMyAccount = () => AccessManager.get(`${resourceName}/myaccount`);
const GetByID = accountID => AccessManager.get(`${resourceName}/${accountID}`);
const Search = query => AccessManager.get(resourceName, { params: query });
const Create = account => AccessManager.post(resourceName, account);
const Update = account => AccessManager.put(resourceName, account);
const UpdateMyAccount = account => AccessManager.put(`${resourceName}/myaccount`, account);
const ReactiveAccount = accountID => AccessManager.put(`${resourceName}/reactive/${accountID}`)
const Delete = accountID => AccessManager.delete(`${resourceName}/${accountID}`);

const GetProfiles = accountID => AccessManager.get(`${resourceName}/${accountID}/profile`);

const SaveProfiles = (accountID, profiles) => {
  const endpointUrl = `${resourceName}/${accountID}/profile`;
  const data = { accountID, profiles };
  return AccessManager.put(endpointUrl, data);
};

const RecoveryPassword = email => AccessManager.post(`${resourceName}/password/forgot`, email);
const ResetPassword = (hash, password) => AccessManager.post(`${resourceName}/password/reset`, { key: hash, password });


/**
 * Verifica se um CPF já está vinculado a uma conta.
 * @param {string} cpf O CPF a ser verificado.
 * @return {Promise}
 */
const CheckCPFUniqueness = cpf => AccessManager.get(
  `${resourceName}/CheckCPFUniqueness`,
  { params: { cpf } },
);


const AccountRepository = {
  CheckCPFUniqueness,
  Create,
  Delete,
  GetAll,
  GetByID,
  GetFromAccessManager,
  GetProfiles,
  GetMyAccount,
  RecoveryPassword,
  ResetPassword,
  SaveProfiles,
  Search,
  Update,
  UpdateMyAccount,
  ReactiveAccount
};

export default AccountRepository;
