import Classe from '@/shared/models/class'

export const state = {
  classes: [],
  classe: new Classe(),
  pagination: {
    count: 0,
    limit: 10,
    index: 1
  }
}
