import AccountRepository from '@/shared/http/repositories/access/account';
import { toast, loading } from '@/shared/utils/UIHelper';
import AuthService from '@/shared/services/auth';
import { IsSuccessCode } from '@/shared/utils/API';
import { JWTService } from '@/shared/services/jwt';
import * as mutation from './mutationTypes';
import { actionTypes as action } from './actionTypes';

export const actions = {
  async [action.SIGN_IN]({ commit }, credentials) {
    try {
      const res = await AuthService.signIn(credentials);

      if (res.success) {
        commit(mutation.SET_JWT, JWTService.GetToken());
        return Promise.resolve(true);
      }

      return Promise.reject(res);
    } catch (err) {
      JWTService.DestroyToken();
      commit(mutation.SET_JWT, null);

      return Promise.reject(false);
    }
  },

  [action.SIGN_OUT]({ commit }, router) {
    AuthService.signOut();
    commit(mutation.SET_USER_IS_LOGGED_IN, false);
    commit(mutation.SET_JWT, null);
    commit(mutation.SET_USER_INFO, null);
    router.replace({ name: 'SignIn' });
  },

  async [action.FETCH_USER_INFO]({ state, commit }, force) {
    if ((force || !state.userInfo) && JWTService.GetToken()) {
      loading.push();
      await AccountRepository.GetFromAccessManager(JWTService.GetTokenPayload().AccountID)
        .then((res) => {
          if (!IsSuccessCode(res)) return Promise.reject();
          commit(mutation.SET_USER_INFO, res.data.data);
          loading.pop();
          return Promise.resolve();
        })
        .catch(() => {
          toast.error('Erro ao obter informações do usuário.')
          loading.pop();
        });
    }
  },

};

export default actions;
