<template>
  <component :is="`Loading${currentLoading}`" />
</template>

<script>
export default {
  components: {
    'Loading1' : () => import('./loading1/loading1.vue'),
    'Loading2' : () => import('./loading2/loading2.vue'),
    'Loading3' : () => import('./loading3/loading3.vue'),
    'Loading4' : () => import('./loading4/loading4.vue'),
    'Loading5' : () => import('./loading5/loading5.vue'),
    'Loading6' : () => import('./loading6/loading6.vue'),
  },
  data: () => ({
    currentLoading: ''
  }),
  beforeMount() {
    this.randomLoading()
  },
  methods: {
    randomLoading() {
      this.currentLoading = Math.floor(Math.random() * (6 - 1 + 1)) + 1
    }
  }
}
</script>

<style>

</style>