import CoreManager from '../../clients/abdaCoreManager'

const resourceName = 'city'

const GetAll = () => CoreManager.get(resourceName)
const GetById = id => CoreManager.get(`${resourceName}/${id}`)
const GetByStateId = stateId => CoreManager.get(`${resourceName}/state/${stateId}`)

const CityRepository = {
  GetAll,
  GetById,
  GetByStateId,
}

export default CityRepository