// import { JWTService } from '@/shared/services/jwt';
// import { toast } from '@/shared/utils/UIHelper';
// import { UserHasRoles, UserHasAnyRole } from '@/shared/utils/acl';

/**
 * @function AssertHasRoles
 * @description Middleware que verifica se o usuário tem as roles necessárias.
 * @param {Array<string>} roles As roles que são necessárias para entrar nesta rota.
 * @returns {function} Middleware;
 */
// export const AssertHasRoles = roles => (to, from, next) => {
//   if (!JWTService.GetToken()) {
//     next({ name: 'SignIn' });
//     return;
//   }

//   const hasRoles = UserHasRoles(roles, JWTService.GetUserRoles());

//   if (!hasRoles) {
//     toast.warning('Você não tem permissão para acessar essa página.');
//     next({ name: 'Dashboard' });
//     return;
//   }

//   next();
// };

/**
 * @function AssertHasAnyRole
 * @description Middleware que verifica se o usuário temalguma  das roles informadas.
 * @param {Array} roles As roles que são informadas para entrar nesta rota.
 * @returns {function} Middleware;
 */
// export const AssertHasAnyRole = roles => (to, from, next) => {
//   if (!JWTService.GetToken()) {
//     next({ name: 'SignIn' });
//     return;
//   }

//   const hasRoles = UserHasAnyRole(roles, JWTService.GetUserRoles());

//   if (!hasRoles) {
//     toast.warning('Você não tem permissão para acessar essa página.');
//     next({ name: 'Dashboard' });
//     return;
//   }

//   next();
// };

function operate(guards, from, to, lastNext, i) {
  const guard = guards[i]
  if (guards.length === i + 1) {
    guard(from, to, lastNext)
  } else {
    guard(from, to, (nextArg) => {
      switch (typeof (nextArg)) {
        case 'undefined':
          operate(guards, from, to, lastNext, i + 1)
          break
        case 'object':
          lastNext(nextArg)
          break
        case 'boolean':
          lastNext(nextArg)
          break
        case 'string':
          lastNext(nextArg)
          break
        default:
      }
    })
  }
}

export function GuardsCheck(ListOfGuards) {
  return (from, to, next) => {
    operate(ListOfGuards, from, to, next, 0)
  }
}

const RouterUtils = {
  // AssertHasRoles,
  GuardsCheck
}

export default RouterUtils
