const WebFormRoutes = [
  {
    path: '/ferramentas/formulario-web',
    meta: { title: 'Formulário WEB' },
    name: 'web-form',
    component: () => import('@/views/tools/web-form/web-form.vue')
  },
  {
    path: '/ferramentas/formulario-web/novo',
    meta: { title: 'Novo Formulário WEB' },
    name: 'NewWebForm',
    component: () => import('@/views/tools/web-form/components/newWebForm/newWebForm.vue')
  },
  {
    path: '/ferramentas/formulario-web/:id',
    meta: { title: 'Editar Formulário Web' },
    name: 'EditWebForm',
    component: () => import('@/views/tools/web-form/components/editWebForm/editWebForm.vue')
  },
  {
    path: '/ferramentas/formulario-web/questoes/:formwebid/questao/novo',
    meta: { title: 'Nova Questão' },
    name: 'NewWebFormQuestion',
    component: () => import('@/views/tools/web-form/components/newWebFormQuestion/newWebFormQuestion.vue')
  },
  {
    path: '/ferramentas/formulario-web/questoes/:formwebid/questao/:id',
    meta: { title: 'Editar Questão' },
    name: 'EditWebFormQuestion',
    component: () => import('@/views/tools/web-form/components/editWebFormQuestion/editWebFormQuestion.vue')
  }
]

export default WebFormRoutes
