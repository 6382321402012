import SocialProjectManager from '../../clients/abdaSocialProjectManager'
import { merge } from 'lodash'

const resourceName = 'teacher'

const GetAll = (name, pagination) => SocialProjectManager.get(resourceName, { 
  params: merge(
    { name: name }, 
     pagination) 
  })
const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)

const GetEmail = id => SocialProjectManager.get(`${resourceName}/teacher/${id}`)

const Create = data => SocialProjectManager.post(resourceName, data)

const Update = data => SocialProjectManager.put(`${resourceName}/${data.id}`, data)

const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)

const AssociateAccount = (id, accountid) => SocialProjectManager.put(`${resourceName}/${id}/account/${accountid}`)

const GetTeacherByAccountID = accountid => SocialProjectManager.get(`${resourceName}/account/${accountid}`)

const GetAllWithoutAccount = () => SocialProjectManager.get(`${resourceName}/without-account`)

const ActivateTeacher = id => SocialProjectManager.get(`${resourceName}/active/${id}`)

const TeacherRepository = {
  GetById,
  GetAll,
  Create,
  Update,
  Delete,
  AssociateAccount,
  GetTeacherByAccountID,
  GetAllWithoutAccount,
  ActivateTeacher,
  GetEmail
}

export default TeacherRepository