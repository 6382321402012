import SocialProjectManager from '../../clients/abdaSocialProjectManager'
import { merge } from 'lodash'

const resourceName = 'socialentity'

const GetAll = (name, pagination) => SocialProjectManager.get(resourceName, { 
  params: merge(
    { name: name }, 
     pagination) 
  })
const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)

const Create = data => SocialProjectManager.post(resourceName, data)

const Update = data => SocialProjectManager.put(`${resourceName}/${data.id}`, data)

const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)

const SocialEntityRepository = {
  GetAll,
  GetById,
  Create,
  Update,
  Delete
}

export default SocialEntityRepository