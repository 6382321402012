const RestaurantMealsRoutes = [
  {
    path: '/restaurante/refeicoes',
    meta: { title: 'Restaurante' },
    name: 'meals',
    component: () => import('@/views/restaurant/Meals.vue')
  }
]

export default RestaurantMealsRoutes
