import AuthRepository from '@/shared/http/repositories/access/auth'
import AccessManager from '@/shared/http/clients/abdaAccessManager'
import { JWTService } from '@/shared/services/jwt'
import { IsSuccessCode } from '@/shared/utils/API'

/**
 * @function handleSignInAttempt
 * @description Trata uma tentativa de sign in.
 * @param {Object} result O resultado da tentativa de sign in.
 * @returns {Object} Resultado do sign in.
 */
const handleSignInAttempt = (result) => {
  const signInResult = {
    success: false,
    data: undefined,
    msg: ''
  }
  
  signInResult.data = result.data

  if (result.code === 0) {
    signInResult.success = true
    JWTService.SaveToken(result.data.token)
    AccessManager.defaults.headers.common.Authorization = `Bearer ${JWTService.GetToken()}`
    return Promise.resolve(signInResult)
  }

  signInResult.success = false;

  if (result.code === -3) {
    signInResult.msg = 'Usuário ou senha inválido';
    return Promise.resolve(signInResult);
  }

  JWTService.DestroyToken()

  if (result.data) {
    signInResult.data = result.data
  }

  return Promise.reject(signInResult)
}

/**
 * @function signIn
 * @description Realiza o sign in de um usuário.
 * @param {Object} credentials As credenciais do usuário.
 * @returns {Boolean} Se o sign in teve sucesso ou não.
 */
const signIn = async (credentials) => {
  if (!credentials) return Promise.reject(new Error({ success: false }))

  return AuthRepository.SignIn(credentials)
    .then(res => Promise.resolve(handleSignInAttempt(res.data)))
    .catch(err => Promise.reject(handleSignInAttempt(err)))
}

/**
 * @function signOut
 * @description Desloga o usuário.
 * @returns {void}
 */
const signOut = () => {
  JWTService.DestroyToken()
}

/**
 * @function validateActivationCode
 * @description Valida um codigo de ativação.
 * @param {string} activationCode O código de validação.
 * @param {*} accountId O id da conta a ser validada.
 * @returns {Promise}
 */
const validateActivationCode = async (activationCode, accountId) =>
  AuthRepository.ValidateActivationCode(activationCode, accountId)
    .then((res) => {
      if (res.data.code === 0) {
        JWTService.SaveToken(res.data.data.token);
        AccessManager.defaults.headers.common.Authorization = `Bearer ${JWTService.GetToken()}`;
        return Promise.resolve({ success: true });
      }

      return Promise.reject({ success: false });
    })
    .catch(() => Promise.reject({ success: false }));

// eslint-disable-next-line consistent-return
const refreshToken = () => {
  const token = JWTService.GetToken()

  if (!token) {
    JWTService.DestroyToken()
    return Promise.reject(new Error())
  }

  return AuthRepository.RefreshToken(token)
    .then((res) => {
      if (!IsSuccessCode(res)) {
        return Promise.reject(new Error())
      }

      JWTService.SaveToken(res.data.data.token)
      return Promise.resolve(new Error())
    })
    .catch(() => {
      JWTService.DestroyToken()
      return Promise.reject(new Error())
    })
}

/**
 * @class AuthService
 * @description Serviço para gerenciar o estado de autenticação do sistema.
 */
export default {
  signIn,
  signOut,
  validateActivationCode,
  refreshToken,
}
