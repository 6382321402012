var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.colors),function(item){return [_c('a',{key:item,class:{
        [_vm.$style.air__sidebar__select__item]: true,
        [_vm.$style.air__sidebar__select__item__active]: _vm.value === item,
        [_vm.$style.air__sidebar__select__item__black]: item === 'dark',
        [_vm.$style.air__sidebar__select__item__white]: item === 'white',
        [_vm.$style.air__sidebar__select__item__gray]: item === 'gray',
        [_vm.$style.air__sidebar__select__item__blue]: item === 'blue',
        [_vm.$style.air__sidebar__select__item__img]: item === 'image',
      },attrs:{"href":"javascript: void(0);"},on:{"click":function($event){return _vm.colorPickerHandler(_vm.setting, item)}}})]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }