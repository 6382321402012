import Vue from 'vue'
import Vuex from 'vuex'
import AppModules from './modules/index'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: false,
  modules: AppModules
})
