import { camelCase } from 'lodash';

let regex = /^\.\/(.*)\/.*.js$/;
let requireModule;

if (process.env.NODE_ENV === '"test"') {
  // eslint-disable-next-line
  require.context = require('require-context');
  regex = /^(.*)\/.*.js$/;
  requireModule = require.context(__dirname, true, /\.js$/);
} else {
  requireModule = require.context('.', true, /\.js$/);
}

const modulesFiles = {};

requireModule.keys().forEach((m) => {
  if (m.match(/(index.js|mixin.js)/)) return;

  const moduleName = camelCase(m.match(regex)[1]);

  modulesFiles[moduleName] = modulesFiles[moduleName] || [];
  modulesFiles[moduleName].push(m);
});

const AppModules = {};

Object.keys(modulesFiles).forEach((moduleName) => {
  const statePath = modulesFiles[moduleName].find(fn => fn.match(/state.js$/));
  const gettersPath = modulesFiles[moduleName].find(fn => fn.match(/getters.js$/));
  const mutationsPath = modulesFiles[moduleName].find(fn => fn.match(/mutations.js$/));
  const actionsPath = modulesFiles[moduleName].find(fn => fn.match(/actions.js$/));

  AppModules[moduleName] = {
    namespaced: true,
    actions: requireModule(actionsPath).actions,
    state: requireModule(statePath).state,
    getters: requireModule(gettersPath).getters,
    mutations: requireModule(mutationsPath).mutations,
  };
});

export default AppModules;
