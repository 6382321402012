const SocialAttendanceTypeRoutes = [
  {
    path: '/cadastros/tipos-de-atendimento-social',
    meta: { title: 'Tipos de Atendimento Social' },
    name: 'SocialAttendanceType',
    component: () => import('@/views/configuration/socialattendancetype/list/SocialAttendanceTypeList.vue')
  },
  {
    path: '/cadastros/tipos-de-atendimento-social/novo',
    meta: { title: 'Novo Tipo de Atendimento Social' },
    name: 'NewSocialAttendanceType',
    component: () => import('@/views/configuration/socialattendancetype/create/SocialAttendanceTypeCreate.vue')
  },
  {
    path: '/cadastros/tipos-de-atendimento-social/:id',
    meta: { title: 'Editar Tipo de Atendimento Social' },
    name: 'EditSocialAttendanceType',
    component: () => import('@/views/configuration/socialattendancetype/edit/SocialAttendanceTypeEdit.vue')
  }
]

export default SocialAttendanceTypeRoutes
