var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('transition-group',{attrs:{"name":"air__menuFlyout__animation","duration":1}},[_vm._l((_vm.renderedFlyoutItems),function(item,index){return [_c('div',{key:index,class:{
        [_vm.$style.air__menuFlyout]: true,
        [_vm.$style.air__menuFlyoutTop]: _vm.settings.menuLayoutType === 'top',
        [_vm.$style.air__menuFlyout__black]: _vm.settings.flyoutMenuColor === 'dark',
        [_vm.$style.air__menuFlyout__white]: _vm.settings.flyoutMenuColor === 'white',
        [_vm.$style.air__menuFlyout__gray]: _vm.settings.flyoutMenuColor === 'gray',
      },style:({
        left: `${item.itemDimensions.left + item.itemDimensions.width / 2}px`,
        top: `${item.itemDimensions.top + item.itemDimensions.height}px`,
      })},[_c('ul',{class:_vm.$style.air__menuTop__list,on:{"mouseenter":function($event){return _vm.handleFlyoutContainerOver(item.key)},"mouseleave":function($event){return _vm.handleFlyoutOut(item.key)}}},[_vm._l((item.items),function(item,index){return [_c('item',{key:index,attrs:{"item":item,"styles":_vm.$style,"activeItem":_vm.activeItem}})]})],2)])]})],2),_c('div',{class:{
      [_vm.$style.air__menuTop]: true,
      [_vm.$style.air__menuTop__mobileToggled]: _vm.settings.isMobileMenuOpen,
      [_vm.$style.air__menuTop__toggled]: _vm.settings.isMenuCollapsed,
      [_vm.$style.air__menuTop__unfixed]: _vm.settings.isMenuUnfixed,
      [_vm.$style.air__menuTop__shadow]: _vm.settings.isMenuShadow,
      [_vm.$style.air__menuTop__flyout]: _vm.settings.menuType === 'flyout',
      [_vm.$style.air__menuTop__compact]: _vm.settings.menuType === 'compact',
      [_vm.$style.air__menuTop__blue]: _vm.settings.menuColor === 'blue',
      [_vm.$style.air__menuTop__white]: _vm.settings.menuColor === 'white',
      [_vm.$style.air__menuTop__gray]: _vm.settings.menuColor === 'gray',
      [_vm.$style.air__menuFlyout__black]: _vm.settings.flyoutMenuColor === 'dark' && _vm.settings.menuType !== 'default',
      [_vm.$style.air__menuFlyout__white]: _vm.settings.flyoutMenuColor === 'white' && _vm.settings.menuType !== 'default',
      [_vm.$style.air__menuFlyout__gray]: _vm.settings.flyoutMenuColor === 'gray' && _vm.settings.menuType !== 'default',
    }},[_c('div',{class:_vm.$style.air__menuTop__outer},[_c('a',{class:_vm.$style.air__menuTop__mobileToggleButton,attrs:{"href":"javascript: void(0);"},on:{"click":_vm.toggleMobileMenu}},[_c('span')]),_c('a',{class:_vm.$style.air__menuTop__logo,attrs:{"href":"/dashboard"}},[_c('img',{staticStyle:{"margin":"1px"},attrs:{"src":"resources/images/menu-abda.png","alt":"abda"}}),_c('div',{class:_vm.$style.air__menuTop__logo__name},[_vm._v("ABDA")]),_c('div',{class:_vm.$style.air__menuTop__logo__descr},[_vm._v("SISTEMA WEB")])]),_c('vue-custom-scrollbar',[_c('div',{class:_vm.$style.air__menuTop__container},[_c('ul',{class:_vm.$style.air__menuTop__list},[_c('li',{class:_vm.$style.air__menuTop__item},[_c('a',{class:_vm.$style.air__menuTop__link,attrs:{"href":"javascript: void(0);"},on:{"click":_vm.toggleSettings}},[_c('i',{staticClass:"fa fa-gears",class:_vm.$style.air__menuTop__icon}),_c('span',[_vm._v("Configurações")])])]),_vm._l((_vm.menuData),function(item,index){return [(!item.children && !item.category)?_c('item',{key:index,attrs:{"item":item,"styles":_vm.$style,"activeItem":_vm.activeItem}}):_vm._e(),(item.children)?_c('sub-menu',{key:index,attrs:{"item":item,"styles":_vm.$style,"activeItem":_vm.activeItem,"activeSubmenu":_vm.activeSubmenu,"handleSubmenuClick":_vm.handleSubmenuClick,"handleFlyoutOver":_vm.handleFlyoutOver,"handleFlyoutOut":_vm.handleFlyoutOut}}):_vm._e()]})],2)])])],1)]),_c('a',{class:_vm.$style.air__menuTop__backdrop,attrs:{"href":"javascript: void(0);"},on:{"click":_vm.toggleMobileMenu}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }