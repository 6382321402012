import StudentModel from '@/shared/models/student'
import {
  SET_STUDENTS,
  SET_STUDENT,
  SET_PAGINATION
} from './mutationTypes'

export const mutations = {
  [SET_STUDENTS]: (state, payload) => {
    return state.students = payload.map(student => new StudentModel(student))
  },

  [SET_STUDENT]: (state, payload) => state.student = new StudentModel(payload),

  [SET_PAGINATION]: (state, { count, pagingLimit, pagingIndex }) => {
    state.pagination = {
      count,
      limit: pagingLimit,
      index: pagingIndex
    }
  }
}
