const Physiology = [
  {
    path: '/fisiologia',
    meta: { title: 'Fisiologia' },
    name: 'physiology',
    component: () => import('@/views/physiology/list/Physiology-List.vue'),
  },
  {
    path: '/fisiologia/temporada/nova',
    meta: { title: 'Nova Turma na Temporada' },
    name: 'NewPhysiology',
    component: () => import('@/views/physiology/edit/Physiology-Edit.vue'),
  },
  {
    path: '/fisiologia/temporada/Editar',
    meta: { title: 'Editar Turma na Temporada' },
    name: 'EditPhysiology',
    component: () => import('@/views/physiology/edit/Physiology-Edit.vue'),
  }
]
export default Physiology
