import { globalActions as authActions } from '@/store/modules/auth/actionTypes';
import { loading, toast } from '@/shared/utils/UIHelper';

const SIGN_IN = 'signIn';

export const actionTypes = {
  SIGN_IN,
};

export const globalActions = {
  SIGN_IN: `signIn/${SIGN_IN}`,
};


export const actions = {

  [SIGN_IN]: async ({ dispatch, state }) => {
    loading.push();

    try {
      const res = await dispatch(authActions.SIGN_IN, state.credentials, { root: true });
      loading.pop();
      return Promise.resolve({ success: true, res });
    } catch (err) {
      toast.error(err.msg)
      loading.pop();
      return Promise.reject({ success: false, err });
    }
  },
};
