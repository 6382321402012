import localeAntd from 'ant-design-vue/lib/locale-provider/en_US'

const messages = {
  topBar: {
    issuesHistory: 'Issues History',
    typeToSearch: 'Start typing to search...',
    actions: 'Actions',
    status: 'Status',
    profileMenu: {
      hello: 'Olá',
      billingPlan: 'Billing Plan',
      role: 'Role',
      email: 'Email',
      phone: 'Telefone',
      editProfile: 'Minha Conta',
      logout: 'Sair',
    },
  },
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
