const AccountRoutes = [
  {
    path: '/contas',
    meta: { title: 'Contas' },
    name: 'Account',
    component: () => import('@/views/admin/account/list/account.vue'),
  },
  {
    path: '/contas/novo',
    meta: { title: 'Nova Conta' },
    name: 'NewAccount',
    component: () => import('@/views/admin/account/edit/accountEdit.vue'),
  },
  {
    path: '/contas/:id',
    meta: { title: 'Editar Conta' },
    name: 'EditAccount',
    component: () => import('@/views/admin/account/edit/accountEdit.vue'),
  },
]
export default AccountRoutes
