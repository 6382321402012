import { mapGetters, mapActions } from 'vuex';
import CommonModule, { MODULE_NAME } from './index';

const CommonMixin = {
  namespaced: CommonModule.namespaced,
  computed: { ...mapGetters(MODULE_NAME, Object.keys(CommonModule.getters)) },
  methods: { ...mapActions(MODULE_NAME, Object.keys(CommonModule.actions)) },
};

export default CommonMixin;
