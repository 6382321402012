<template>
  <div>
    <div
      :class="{[$style.air__sidebar]: true, [$style.air__sidebar__toggled]: settings.isSidebarOpen}"
    >
      <vue-custom-scrollbar :style="{ height: '100%' }">
        <div :class="$style.air__sidebar__inner">
          <a
            href="javascript: void(0);"
            :class="$style.air__sidebar__close"
            class="fa fa-times"
            @click="toggleSidebar()"
          />
          <h5>
            <strong>Configuração Pessoal</strong>
          </h5>
          <div class="air__utils__line" :style="{ marginTop: '25px', marginBottom: '30px' }" />
          <p class="text-muted mb-5">
            Este componente oferece a possibilidade de editar e personalizar elementos internos, de acordo com a sua preferência.
          </p>
          <div :class="$style.air__sidebar__type">
            <div :class="$style.air__sidebar__type__title">
              <span>Opções de Menu</span>
            </div>
            <div :class="$style.air__sidebar__type__items">
              <a-radio-group :defaultValue="settings.menuLayoutType" @change="selectMenuLayoutType">
                <div class="row">
                  <div class="col-7">
                    <div class="mb-2">
                      <a-radio :value="'left'">Menu à Esquerda</a-radio>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-2">
                      <a-radio :value="'top'">Menu em Cima</a-radio>
                    </div>
                  </div>
                </div>
              </a-radio-group>
            </div>
          </div>
          <div :class="$style.air__sidebar__type">
            <div :class="$style.air__sidebar__type__title">
              <span>Opções do Menu à Esquerda</span>
            </div>
            <div :class="$style.air__sidebar__type__items">
              <a-radio-group :defaultValue="settings.menuType" @change="selectMenuType">
                <div class="row">
                  <div class="col-6">
                    <div class="mb-2">
                      <a-radio
                        :value="'default'"
                        :disabled="settings.menuLayoutType !== 'left'"
                      >Padrão</a-radio>
                    </div>
                    <div class="mb-2">
                      <a-radio
                        :value="'flyout'"
                        :disabled="settings.menuLayoutType !== 'left'"
                      >Flutuante</a-radio>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-2">
                      <a-radio
                        :value="'compact'"
                        :disabled="settings.menuLayoutType !== 'left'"
                      >Compacto</a-radio>
                    </div>
                  </div>
                </div>
              </a-radio-group>
            </div>
          </div>
          <div :class="$style.air__sidebar__item">
            <div :class="$style.air__sidebar__label">Menu à Esquerda Com Ícones</div>
            <div :class="$style.air__sidebar__container">
              <a-switch
                :checked="settings.isMenuCollapsed"
                @click="settingChange($event, 'isMenuCollapsed')"
              />
            </div>
          </div>
          <div :class="$style.air__sidebar__item">
            <div :class="$style.air__sidebar__label">Largura Máxima da Página</div>
            <div :class="$style.air__sidebar__container">
              <a-switch
                :checked="settings.isContentNoMaxWidth"
                @click="settingChange($event, 'isContentNoMaxWidth')"
              />
            </div>
          </div>
          <div :class="$style.air__sidebar__item">
            <div :class="$style.air__sidebar__label">Soltar Menu à Esquerda</div>
            <div :class="$style.air__sidebar__container">
              <a-switch
                :checked="settings.isMenuUnfixed"
                @click="settingChange($event, 'isMenuUnfixed')"
              />
            </div>
          </div>
          <div :class="$style.air__sidebar__item">
            <div :class="$style.air__sidebar__label">Fixar Barra do Topo</div>
            <div :class="$style.air__sidebar__container">
              <a-switch
                :checked="settings.isTopbarFixed"
                @click="settingChange($event, 'isTopbarFixed')"
              />
            </div>
          </div>
          <div :class="$style.air__sidebar__item">
            <div :class="$style.air__sidebar__label">Cores do Menu</div>
            <div :class="$style.air__sidebar__container">
              <air-color-picker
                :value="settings.menuColor"
                :setting="'menuColor'"
                :colors="['white', 'gray', 'blue', 'dark']"
              />
            </div>
          </div>
          <div :class="$style.air__sidebar__item">
            <div :class="$style.air__sidebar__label">Cores do Menu Flutuante</div>
            <div :class="$style.air__sidebar__container">
              <air-color-picker
                :value="settings.flyoutMenuColor"
                :setting="'flyoutMenuColor'"
                :colors="['white', 'gray', 'blue', 'dark']"
              />
            </div>
          </div>
          <div :class="$style.air__sidebar__item">
            <div :class="$style.air__sidebar__label">Cores do Login</div>
            <div :class="$style.air__sidebar__container">
              <air-color-picker
                :value="settings.systemLayoutColor"
                :setting="'systemLayoutColor'"
                :colors="['white', 'gray', 'blue', 'dark', 'image']"
              />
            </div>
          </div>
          <div :class="$style.air__sidebar__item">
            <div :class="$style.air__sidebar__label">Fundo Cinza</div>
            <div :class="$style.air__sidebar__container">
              <a-switch
                :checked="settings.isGrayBackground"
                @click="settingChange($event, 'isGrayBackground')"
              />
            </div>
          </div>
          <div :class="$style.air__sidebar__item">
            <div :class="$style.air__sidebar__label">Topo Cinza</div>
            <div :class="$style.air__sidebar__container">
              <a-switch
                :checked="settings.isGrayTopbar"
                @click="settingChange($event, 'isGrayTopbar')"
              />
            </div>
          </div>
          <div :class="$style.air__sidebar__item">
            <div :class="$style.air__sidebar__label">Linhas com Sombra</div>
            <div :class="$style.air__sidebar__container">
              <a-switch
                :checked="settings.isCardShadow"
                @click="settingChange($event, 'isCardShadow')"
              />
            </div>
          </div>
          <div :class="$style.air__sidebar__item">
            <div :class="$style.air__sidebar__label">Menu com Sombra</div>
            <div :class="$style.air__sidebar__container">
              <a-switch
                :checked="settings.isMenuShadow"
                @click="settingChange($event, 'isMenuShadow')"
              />
            </div>
          </div>
        </div>
      </vue-custom-scrollbar>
    </div>
   
  </div>
</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar'
import { mapActions, mapState } from 'vuex'
import AirColorPicker from '@/components/layout/Sidebar/partials/colorPicker'
import { globalActions as settings } from '@/store/modules/settings/actions'

export default {
  components: { vueCustomScrollbar, AirColorPicker },
  computed: mapState(['settings']),
  methods: {
    ...mapActions({
      changeSettings: settings.CHANGE_SETTING,
    }),
    toggleSidebar: function () {
      const setting = 'isSidebarOpen'
      const value = !this.settings[setting]
      this.changeSettings({ setting, value })
    },
    settingChange(e, setting) {
      const value = !this.settings[setting]
      this.changeSettings({ setting, value })
    },
    selectMenuType(e) {
      const setting = 'menuType'
      const { value } = e.target
      this.changeSettings({ setting, value })
    },
    selectMenuLayoutType(e) {
      const setting = 'menuLayoutType'
      const { value } = e.target
      this.changeSettings({ setting, value })
    },
    selectRouterAnimation(value) {
      const setting = 'routerAnimation'
      this.changeSettings({ setting, value })
    },
    selectLocale(value) {
      const setting = 'locale'
      this.changeSettings({ setting, value })
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
