import {
  SET_MODALITIES_LOCAL_TRAININGS,
  SET_LOCAL_TRAININGS,
  SET_MODALITIES,
  CLEAR_LOCAL_TRAININGS,
  CLEAR_MODALITIES
} from './mutationTypes'

export const mutations = {
  [SET_MODALITIES_LOCAL_TRAININGS]: (state, payload) => state.modalitiesLocalTrainings = payload,
  [SET_LOCAL_TRAININGS]: (state, payload) => state.localTrainings.push(payload),
  [SET_MODALITIES]: (state, payload) => state.modalities.push(payload),
  [CLEAR_LOCAL_TRAININGS]: state => state.localTrainings = [],
  [CLEAR_MODALITIES]: state => state.modalities = []
}
