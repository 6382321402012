import CidRepository from '@/shared/http/repositories/socialProject/cid'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import * as mutation from './mutationTypes'
import { actionTypes as action } from './actionTypes'

export const actions = {
  [action.FETCH_BY_CID_CODE]: async ({ commit }, cidCode) => {
    try {
      loading.push()

      const res = await CidRepository.GetByCidCode(cidCode)
      if (!IsSuccessCode(res)) return Promise.reject()

      commit(mutation.SET_CID, res.data.data)
      loading.pop()
      return Promise.resolve()
    } catch (error) {
      loading.pop()
      toast.info('CID não encontrado', 'AVISO')
    }
  },

  [action.FETCH_ALL_CID_CODE]: async ({ commit }, cidCode) => {
    try {
      loading.push()

      const res = await CidRepository.GetAllCidCode(cidCode)
      if (!IsSuccessCode(res)) return Promise.reject()

      commit(mutation.SET_CIDS, res.data.data)
      loading.pop()
      return Promise.resolve()
    } catch (error) {
      loading.pop()
      toast.error('Erro ao buscar cids', 'ERRO')
    }
  }
}
