const SET_PERMISSIONS = 'setPermissions'

export const mutationTypes = {
  SET_PERMISSIONS
}

export const mutations = {
  [SET_PERMISSIONS] (state, payload) {
    state.permissions = payload
  }
}
