const WaitingListWebRoutes = [
  {
    path: '/lista-de-espera-web',
    meta: { title: 'Lista de espera web' },
    name: 'WaitingListWeb',
    component: () => import('@/views/waitinglist/waitingList-web/list/WaitingListWeb.vue')
  },
  {
    path: '/lista-de-espera-web/editar/:id',
    meta: { title: 'Editar Lista de espera web' },
    name: 'WaitingListWebEdit',
    component: () => import('@/views/waitinglist/waitingList-web/edit/WaitingListWebEdit.vue')
  }
]

export default WaitingListWebRoutes
