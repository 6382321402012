import { isArray, get, find } from 'lodash'
import { loading, toast } from './UIHelper'

const RESPONSE_CODE = {
  SUCCESS: 0,
  VALIDATION_ERROR: -13,
}

export const IsSuccessCode = (req) => {
  if (isArray(req)) {
    return !find(req, r => get(r, 'data.code') !== RESPONSE_CODE.SUCCESS)
  }

  return req.data.code === RESPONSE_CODE.SUCCESS
}

export const IsValidationErrorCode = (req) => {
  if (isArray(req)) {
    return !find(req, r => get(r, 'data.code') !== RESPONSE_CODE.VALIDATION_ERROR)
  }

  return req.data.code === RESPONSE_CODE.VALIDATION_ERROR
}

/**
 * Retorna uma function que:
 *   - Remove a exibição de um loader
 *   - Exibe um toastr de erro com uma mensagem
 *   - Retorna uma rejeição de promise caso o parâmetro rejectPromise seja true.
 * @param {string} msg A mensagem para ser exibida no toastr.
 * @param {boolean} rejectPromise Se true, retorna uma rejeição de Promise.
 * @return {function}
 */
export const FailWithMessage = (msg, rejectPromise) => () => {
  loading.pop()
  toast.error(msg)
  if (rejectPromise) return Promise.reject(msg)
  return undefined
}

const APIUtils = {
  IsSuccessCode,
  FailWithMessage,
}

export default APIUtils
