const EmployeeQrCodeRoutes = [
  {
    path: '/ferramentas/qrcode-funcionario-e-visitante',
    meta: { title: 'QrCode Empregado' },
    name: 'EmployeeQRCode',
    component: () => import('@/views/tools/employee-single-qrcode/EmployeeAndSingleQRCode.vue')
  }
]

export default EmployeeQrCodeRoutes
