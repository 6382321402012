const MessageSmsRoutes = [
  {
    path: '/cadastros/mensagem-sms',
    meta: { title: 'Mensagem SMS' },
    name: 'MessageSms',
    component: () => import('@/views/configuration/messagesms/list/MessageSms.vue')
  },
  {
    path: '/cadastros/mensagem-sms/novo',
    meta: { title: 'Nova Mensagem SMS' },
    name: 'NewMessageSms',
    component: () => import('@/views/configuration/messagesms/create/MessageSmsCreate.vue')
  },
  {
    path: '/cadastros/mensagem-sms/:id',
    meta: { title: 'Editar Mensagem SMS' },
    name: 'EditMessageSms',
    component: () => import('@/views/configuration/messagesms/edit/MessageSmsEdit.vue')
  }
]

export default MessageSmsRoutes
