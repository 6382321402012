import AdminRoutes from './administration/admin'
import PublicRoutes from './public/public'

const Routes = [
  ...AdminRoutes,
  ...PublicRoutes,
  {
    path: '*',
    meta: {
      title: 'Página não encontrada',
    },
    component: () => import('@/views/system/404')
  }
]

export default Routes
