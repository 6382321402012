const SocialEntityRoutes = [
  {
    path: '/cadastros/entidades-sociais',
    meta: { title: 'Entidades Sociais' },
    name: 'SocialEntity',
    component: () => import('@/views/configuration/socialentity/list/SocialEntityList.vue')
  },
  {
    path: '/cadastros/entidades-sociais/novo',
    meta: { title: 'Nova Entidade Social' },
    name: 'NewSocialEntity',
    component: () => import('@/views/configuration/socialentity/create/SocialEntityCreate.vue')
  },
  {
    path: '/cadastros/entidades-sociais/:id',
    meta: { title: 'Editar Entidade Social' },
    name: 'EditSocialEntity',
    component: () => import('@/views/configuration/socialentity/edit/SocialEntityEdit.vue')
  }
]

export default SocialEntityRoutes
