import StudentModel from '@/shared/models/student'

export const state = {
  students: [],
  student: new StudentModel(),
  pagination: {
    count: 0,
    limit: 10,
    index: 1
  }
}
