const LocalTrainingRoutes = [
  {
    path: '/cadastros/locais-de-treinamento',
    meta: { title: 'Locais de Treinamento' },
    name: 'LocalTraining',
    component: () => import('@/views/configuration/localtraining/list/LocalTrainingList.vue')
  },
  {
    path: '/cadastros/locais-de-treinamento/novo',
    meta: { title: 'Novo Local de Treinamento' },
    name: 'NewLocalTraining',
    component: () => import('@/views/configuration/localtraining/create/LocalTrainingCreate.vue')
  },
  {
    path: '/cadastros/locais-de-treinamento/:id',
    meta: { title: 'Editar Local de Treinamento' },
    name: 'EditLocalTraining',
    component: () => import('@/views/configuration/localtraining/edit/LocalTrainingEdit.vue')
  }
]

export default LocalTrainingRoutes
