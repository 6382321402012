import { getNamespacedBy } from '@/store/utils'

export const actionTypes = {
  FETCH_STUDENTS: 'fetchStudents',
  SET_STUDENTS: 'setStudents',
  SET_PAGINATION: 'setPagination',
  SET_STUDENT: 'setStudent'
}

export const globalActions = getNamespacedBy('student', actionTypes)
