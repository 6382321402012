import ClassRepository from '@/shared/http/repositories/socialProject/class'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import * as mutation from './mutationTypes'
import { actionTypes as action } from './actionTypes'

export const actions = {
  [action.FETCH_CLASSES]: async ({ dispatch, commit }, { criteria, pagination }) => {
    try {
      loading.push()
      const res = await ClassRepository.GetAll(criteria, pagination)
      if (!IsSuccessCode(res)) return Promise.reject()

      commit(mutation.SET_CLASSES, res.data.data)
      dispatch('setPagination', res.data)

      loading.pop()
      return Promise.resolve()
    } catch (error) {
      loading.pop()
      toast.error('Erro ao carregar turmas', 'ERRO')
    }
  },

  [action.SET_CLASS]: ({ commit }, classe) => {
    commit(mutation.SET_CLASS, classe)
  },

  [action.SET_PAGINATION]: ({ commit }, { count, pagingLimit, pagingIndex }) => {
    commit(mutation.SET_PAGINATION, { count, pagingLimit, pagingIndex })
  },
}
