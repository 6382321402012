const AnamneseRoutes = [
  {
    path: '/cadastros/anamese',
    meta: { title: 'Anamnese' },
    name: 'Anamnese',
    component: () => import('@/views/configuration/anamnese/list/AnamneseList.vue')
  },
  {
    path: '/cadastros/anamese/novo',
    meta: { title: 'Nova Anamnese' },
    name: 'NewAnamnese',
    component: () => import('@/views/configuration/anamnese/create/AnamneseCreate.vue')
  },
  {
    path: '/cadastros/anamese/:id',
    meta: { title: 'Editar Anamnese' },
    name: 'EditAnamnese',
    component: () => import('@/views/configuration/anamnese/edit/AnamneseEdit.vue')
  }
]

export default AnamneseRoutes
