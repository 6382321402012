const StudentsRoutes = [
  {
    path: '/alunos',
    meta: { title: 'Aluno' },
    name: 'Student',
    component: () => import('@/views/student/Student.vue')
  }
]

export default StudentsRoutes
