import SocialProjectManager from '../../clients/abdaSocialProjectManager'

const resourceName = 'deficiencytype'

const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)
const GetAll = () => SocialProjectManager.get(resourceName)

const Create = data => SocialProjectManager.post(resourceName, data)

const Update = data => SocialProjectManager.put(`${resourceName}/${data.id}`, data)

const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)

const DeficiencyTypeRepository = {
  GetById,
  GetAll,
  Create,
  Update,
  Delete
}

export default DeficiencyTypeRepository