const daysWeek = {
  MONDAY: {
    value: 1,
    text: 'Segunda-Feira'
  },

  TUESDAY: {
    value: 2,
    text: 'Terça-Feira'
  },

  WEDNESDAY: {
    value: 3,
    text: 'Quarta-Feira'
  },

  THURSDAY: {
    value: 4,
    text: 'Quinta-Feira'
  },

  FRvalueAY: {
    value: 5,
    text: 'Sexta-Feira'
  },

  SATURDAY: {
    value: 6,
    text: 'Sábado'
  },

  SUNDAY: {
    value: 7,
    text: 'Domingo'
  }
}

Object.freeze(daysWeek)

export default daysWeek