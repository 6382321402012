const SchoolTempRoutes = [
  {
    path: '/schooltemp',
    meta: { title: 'SchoolTempList' },
    // component: () => import('@/views/school-list/SchoolList.vue'),
    children: [
      {
        path: ':id',
        meta: { title: 'SchoolTempEdit' },
        // component: () => import('@/views/school-edit/SchoolEdit.vue'),
      }
    ]
  }
]

export default SchoolTempRoutes
