const BatchStatusRoutes = [
  {
    path: '/ferramentas/situacao-em-lote',
    meta: { title: 'Situação em Lote' },
    name: 'BatchStatus',
    component: () => import('@/views/tools/batch-status/BatchStatus.vue')
  }
]

export default BatchStatusRoutes
