export const state = {
  account: {
    id: null,
    companyId: null,
    company: null,
    name: null,
    email: null,
    cellPhone: null,
    password: null,
    isConfirmed: null,
    lastAccess: null,
  },
  accounts: [],
};

export default state;
