import CoreManager from '../../clients/abdaCoreManager'

const resourceName = 'state'

const GetAll = () => CoreManager.get(resourceName)
const GetById = id => CoreManager.get(`${resourceName}/${id}`)

const StateRepository = {
  GetAll,
  GetById,
}

export default StateRepository