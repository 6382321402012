import AccountRepository from '@/shared/http/repositories/access/account';
import { mutationTypes as mutation } from './mutations';

const GET_ACCOUNT_BY_ID = 'getAccountById';
const GET_ALL_ACCOUNTS = 'getAllAccounts';

export const actionTypes = {
  GET_ACCOUNT_BY_ID,
  GET_ALL_ACCOUNTS,
};

export const actions = {
  [GET_ACCOUNT_BY_ID]({ commit }, payload) {
    commit(mutation.SET_ACCOUNT, payload);
  },

  async [GET_ALL_ACCOUNTS]({ commit }, payload) {
    AccountRepository.GetAll(payload)
      .then((res) => {
        commit(mutation.SET_ACCOUNTS, res.data);
      });
  },
};
