import SocialProjectManager from '../../clients/abdaSocialProjectManager'
import { merge } from 'lodash'

const resource = 'class'
const resourceModality = 'modality'
const resourceLocal = 'localTraining'

const GetAll = (criteria, pagination) => SocialProjectManager.get(resource, { 
  params: merge(criteria, pagination) 
})

const GetAllByCategory = categoryId => SocialProjectManager.get(`${resource}/category/${categoryId}`)
const GetAllByLocalTrainingAndModality = (localTrainingId, modalityId) => SocialProjectManager.get(`${resource}/${resourceLocal}/${localTrainingId}/${resourceModality}/${modalityId}`) 
const GetById = id => SocialProjectManager.get(`${resource}/${id}`)
const GetSearch = data => SocialProjectManager.get(`${resource}/search`, data)

const Create = data => SocialProjectManager.post(resource, data)

const Update = data => SocialProjectManager.put(`${resource}/${data.id}`, data)

const Delete = id => SocialProjectManager.delete(`${resource}/${id}`)

const ClassRepository = {
  GetAll,
  GetAllByCategory,
  GetAllByLocalTrainingAndModality,
  GetById,
  GetSearch,
  Create,
  Update,
  Delete
}

export default ClassRepository