const WaitingListRoutes = [
  {
    path: '/lista-de-espera',
    meta: { title: 'Listagem de Espera' },
    name: 'WaitingList',
    component: () => import('@/views/waitinglist/WaitingList-List.vue')
  },
  {
    path: '/lista-de-espera/novo',
    meta: { title: 'Nova Criança' },
    name: 'NewChildren',
    component: () => import('@/views/waitinglist/WaitingList-Edit.vue')
  },
  {
    path: '/lista-de-espera/:id',
    meta: { title: 'Editar Criança' },
    name: 'EditChildren',
    component: () => import('@/views/waitinglist/WaitingList-Edit.vue')
  }
]

export default WaitingListRoutes
