<template>
  <div :class="$style.topbar">
    <div class="mr-md-4 mr-auto">
      <air-search />
    </div>
    <div class="mr-auto d-none d-md-block">
    </div>
    <div class>
      <air-user-menu />
    </div>
  </div>
</template>

<script>
import AirSearch from '@/components/layout/TopBar/Search'
import AirUserMenu from '@/components/layout/TopBar/UserMenu'

export default {
  components: {
    AirSearch,
    AirUserMenu,
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
