import SocialProjectManager from '../../clients/abdaSocialProjectManager'

const resourceName = 'modalitylocaltraining'

const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)
const GetAll = () => SocialProjectManager.get(resourceName)

const ModalityLocalTrainingRepository = {
  GetById,
  GetAll
}

export default ModalityLocalTrainingRepository