import SocialProjectManager from '../../clients/abdaSocialProjectManager'

const resourceName = 'healthplan'

const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)
const GetAll = () => SocialProjectManager.get(resourceName)

const HealthPlanRepository = {
  GetById,
  GetAll
}

export default HealthPlanRepository