const WaitingListResultTestRoutes = [
  {
    path: '/resultado-testes',
    meta: { title: 'Resultados dos Testes' },
    name: 'WaitingListResultTest',
    component: () => import('@/views/waitinglist/waitinglist-result-test/WaitingList-ResultTest.vue')
  }
]

export default WaitingListResultTestRoutes
