const SET_USER_INFO = 'setUserInfo';
const SET_USER_IS_LOGGED_IN = 'setUserIsLoggedIn';
const SET_JWT = 'setJWT';

export const mutationTypes = {
  SET_USER_INFO,
  SET_USER_IS_LOGGED_IN,
  SET_JWT,
};

export const mutations = {
  [SET_USER_INFO](state, payload) {
    state.userInfo = payload;
  },

  [SET_USER_IS_LOGGED_IN](state, payload) {
    state.userIsLoggedIn = payload;
  },

  [SET_JWT](state, payload) {
    state.jwt = payload;
  },
};
