import router from '@/router/router'
import { JWTService } from '@/shared/services/jwt'
import ClientFactory from '@/shared/factories/client'

const configFiles = {
  baseURL: process.env.VUE_APP_ABDABaseURLProjectManager,
  headers: { 'Content-Type': 'multipart/form-data' }
}

const APIClientUpload = ClientFactory.New(configFiles)

APIClientUpload.interceptors.request.use((request) => {
  const token = JWTService.GetToken()

  /* eslint-disable-next-line */
  if (token) request.headers.Authorization = `Bearer ${JWTService.GetToken()}`

  return request
}, error => Promise.reject(error))

APIClientUpload.interceptors.response.use(response => response, (error) => {
  if (error.response.status.toString().match(/4\d[13]/)) {
    JWTService.DestroyToken()
    router.replace({ name: 'SignIn' })
  }
  return Promise.reject(error)
})

export default APIClientUpload
