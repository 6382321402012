var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{class:{
    [_vm.styles.air__menuTop__item]: true,
    [_vm.styles.air__menuTop__item__active]: _vm.item.key === _vm.activeItem,
  }},[(_vm.item.url)?_c('router-link',{class:_vm.styles.air__menuTop__link,attrs:{"to":_vm.item.url}},[(_vm.item.icon)?_c('i',{class:{
        [_vm.item.icon]: true,
        [_vm.styles.air__menuTop__icon]: true,
      }}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.item.title))])]):_vm._e(),(!_vm.item.url)?_c('a',{class:_vm.styles.air__menuTop__link,attrs:{"href":"javascript: void(0);"}},[(_vm.item.icon)?_c('i',{class:{
        [_vm.item.icon]: true,
        [_vm.styles.air__menuTop__icon]: true,
      }}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.item.title))])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }