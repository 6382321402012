<template>
  <a-dropdown :trigger="['click']" placement="bottomLeft">
    <div :class="$style.dropdown">
      <a-badge :count="count">
        <a-avatar shape="square" icon="user" size="large" :class="$style.avatar" />
      </a-badge>
    </div>
    <a-menu slot="overlay">
      <a-menu-item>
        <strong>{{ $t('topBar.profileMenu.hello') }},</strong>
        {{ loggedInUser.name }}
        <div>
          <!-- <strong>{{ $t('topBar.profileMenu.role') }}:</strong> -->
          <strong>Perfil:</strong> Administrador
        </div>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <div>
          <strong class="mr-1">{{ $t('topBar.profileMenu.email') }}:</strong>
          {{ loggedInUser.email }}
        </div>
        <div>
          <strong class="mr-1">{{ $t('topBar.profileMenu.phone') }}:</strong> -
        </div>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <a href="/minha-conta">
          <i :class="$style.menuIcon" class="fa fa-wpforms"></i>
          {{ $t('topBar.profileMenu.editProfile') }}
        </a>
      </a-menu-item>
      <a-menu-item>
        <a href="javascript: void(0);" @click="signOut($router)">
          <i :class="$style.menuIcon" class="fa fa-sign-out"></i>
          {{ $t('topBar.profileMenu.logout') }}
        </a>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { globalGetters as AuthGetters } from '@/store/modules/auth/getters'
import { globalActions as AuthActions } from '@/store/modules/auth/actionTypes'

export default {
  data: function () {
    return {
      count: 0
    }
  },
  computed: {
    ...mapGetters({
      loggedInUser: AuthGetters.LOGGED_IN_USER
    })
  },
  methods: {
    ...mapActions({
      signOut: AuthActions.SIGN_OUT
    }),
    // logout() {
    //  this.$auth.logout()
    // }
  }
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
