import store from 'store'

const STORED_SETTINGS = storedSettings => {
  const settings = {}
  Object.keys(storedSettings).forEach(key => {
    const item = store.get(`app.settings.${key}`)
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key]
  })
  return settings
}

export const state = {
    ...STORED_SETTINGS({
      locale: 'en-US',
      isSidebarOpen: false,
      isSupportChatOpen: false,
      isMobileView: false,
      isTabletView: false,
      isMobileMenuOpen: false,
      isMenuCollapsed: false,
      isMenuShadow: false,
      isMenuUnfixed: true,
      menuLayoutType: 'left', // left, top, top-dark, nomenu
      menuType: 'default', // default, flyout, compact
      menuColor: 'gray', // dark, blue, gray, white
      flyoutMenuColor: 'blue', // dark, blue, gray, white
      systemLayoutColor: 'gray', // white, dark, blue, gray, image
      isTopbarFixed: false,
      isFooterDark: false,
      isContentNoMaxWidth: true,
      isAppMaxWidth: false,
      isGrayBackground: false,
      isGrayTopbar: false,
      isCardShadow: false,
      isSquaredBorders: false,
      isBorderless: false,
      routerAnimation: 'slide-fadein-up', // none, slide-fadein-up, slide-fadein-right, fadein, zoom-fadein
    })
};

export default state