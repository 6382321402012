const OccurrenceTypeRoutes = [
  {
    path: '/cadastros/tipos-de-ocorrencia',
    meta: { title: 'Tipos de Ocorrência' },
    name: 'OccurrenceType',
    component: () => import('@/views/configuration/occurrencetype/list/OccurrenceTypeList.vue')
  },
  {
    path: '/cadastros/tipos-de-ocorrencia/novo',
    meta: { title: 'Novo Tipo de Ocorrência' },
    name: 'NewOccurrenceType',
    component: () => import('@/views/configuration/occurrencetype/create/OccurrenceTypeCreate.vue')
  },
  {
    path: '/cadastros/tipos-de-ocorrencia/:id',
    meta: { title: 'Editar Tipo de Ocorrência' },
    name: 'EditOccurrenceType',
    component: () => import('@/views/configuration/occurrencetype/edit/OccurrenceTypeEdit.vue')
  }
]

export default OccurrenceTypeRoutes
