import AppLayout from '@/layouts/App'
import { GuardsCheck } from '../../utils'
import AssertLoggedIn from '../../middlewares/AssertLoggedIn'

import AccountRoutes from './account'
import MyProfileRoutes from './myProfile'
import UserConfigRoutes from './userConfiguration'
import GroupsRoutes from './groups'
import SmsLogsRoutes from './smsLogs'
import QrCodesRoutes from './qrCode-list'

import ChartsRoutes from '../charts/charts'
import ClassRoutes from '../class/class'
import ConfigurationRoutes from '../configuration/index'
import ReportRoutes from '../report/report'
import SchoolTempRoutes from '../schoolTemp/schoolTemp'
import StudentsRoutes from '../student/students'
import WaitingListRoutes from '../waitingList/index'
import ToolsRoutes from '../tools/index'

import TeacherRoutes from '../teacher/index'

import Physiology from '../physiology/physiology.js'

import GymRating from '../gym-rating/gym-rating.js'

import RestaurantMeals from '../restaurant/index'

const AdminRoutes = [
  {
    path: '/',
    component: AppLayout,
    beforeEnter: GuardsCheck([AssertLoggedIn]),
    children: [
      ...ChartsRoutes,
      ...ClassRoutes,
      ...ConfigurationRoutes,
      ...ReportRoutes,
      ...SchoolTempRoutes,
      ...StudentsRoutes,
      ...WaitingListRoutes,
      ...AccountRoutes,
      ...MyProfileRoutes,
      ...UserConfigRoutes,
      ...ToolsRoutes,
      ...RestaurantMeals,
      ...GroupsRoutes,
      ...SmsLogsRoutes,
      ...Physiology,
      ...GymRating,
      ...QrCodesRoutes,
      ...TeacherRoutes,
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: { title: 'Dashboard' },
        component: () => import('@/views/dashboard/dashboard.vue'),
      },
      // 404
      {
        path: '/404',
        meta: {
          title: 'Error 404',
        },
        component: () => import('@/views/system/404'),
      }
    ]
  }
]

export default AdminRoutes
