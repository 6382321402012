import AttachmentTypeRoutes from './attachmentType'
import BenefitTypeRoutes from './benefitType'
import CategoryRoutes from './category'
import LocalTrainingRoutes from './localTraining'
import ModalityRoutes from './modality'
import OccurrenceTypeRoutes from './occurrenceType'
import SchoolRoutes from './school'
import SocialAttendanceTypeRoutes from './socialAttendanceType'
import SocialEntityRoutes from './socialEntity'
import TeacherRoutes from './teacher'
import AnamneseRoutes from './anamnese'
import QuestionsRoutes from './question'
import MessageSmsRoutes from './smsservice'

const ConfigurationRoutes = [
  ...AttachmentTypeRoutes,
  ...BenefitTypeRoutes,
  ...CategoryRoutes,
  ...LocalTrainingRoutes,
  ...ModalityRoutes,
  ...OccurrenceTypeRoutes,
  ...SchoolRoutes,
  ...SocialAttendanceTypeRoutes,
  ...SocialEntityRoutes,
  ...TeacherRoutes,
  ...AnamneseRoutes,
  ...QuestionsRoutes,
  ...MessageSmsRoutes
]

export default ConfigurationRoutes
