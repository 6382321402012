<template>
  <div>
    <template v-for="item in colors">
      <a
        :key="item"
        href="javascript: void(0);"
        @click="colorPickerHandler(setting, item)"
        :class="{
          [$style.air__sidebar__select__item]: true,
          [$style.air__sidebar__select__item__active]: value === item,
          [$style.air__sidebar__select__item__black]: item === 'dark',
          [$style.air__sidebar__select__item__white]: item === 'white',
          [$style.air__sidebar__select__item__gray]: item === 'gray',
          [$style.air__sidebar__select__item__blue]: item === 'blue',
          [$style.air__sidebar__select__item__img]: item === 'image',
        }"
      />
    </template>
  </div>
</template>

<script>
import { globalActions as settings } from '@/store/modules/settings/actions'
import { mapActions } from 'vuex'

export default {
  props: {
    setting: String,
    value: String,
    colors: Array,
  },
  methods: {
     ...mapActions({
      changeSettings: settings.CHANGE_SETTING,
    }),
    colorPickerHandler(setting, value) {
      this.changeSettings({ setting, value })
    },
  },
}
</script>

<style lang="scss" module>
@import "../style.module.scss";
</style>
