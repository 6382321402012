import { get, isString, isArray } from 'lodash'
import { Base64 } from 'js-base64'

export const JWT_KEY = 'ACCESS_TOKEN'

/**
 * @function SaveToken
 * @description Armazena um JWT no localStorage.
 * @param {string} token O JWT para ser armazenado no localStorage.
 * @returns {void}
 */
const SaveToken = (token) => {
  window.localStorage.setItem(JWT_KEY, token)
}

/**
 * @function GetToken
 * @description Obtém o JWT armazenado no localStorage.
 * @returns {string} O JWT armazenado no localStorage.
 */
const GetToken = () => window.localStorage.getItem(JWT_KEY)

/**
 * @function GetTokenHeader
 * @description Obtém o header do JWT armazenado no localStorage.
 * @returns {Object} O header do JWT armazenado no localStorage.
 */
const GetTokenHeader = () => {
  const rawToken = GetToken()
  const rawHeader = rawToken.split('.')[0]
  const decodedRawHeader = Base64.decode(rawHeader)
  const decodedHeader = JSON.parse(decodedRawHeader)
  return decodedHeader
}

/**
 * @function GetTokenPayload
 * @description Obtém o payload do JWT armazenado no localStorage.
 * @returns {Object} O payload do JWT armazenado no localStorage.
 */
const GetTokenPayload = () => {
  const rawToken = GetToken()
  const rawPayload = rawToken.split('.')[1]
  const decodedRawPayload = Base64.decode(rawPayload)
  const decodedPayload = JSON.parse(decodedRawPayload)
  return decodedPayload
}

/**
 * @function DestroyToken
 * @description Destrói o JWT armazenado no localStorage.
 * @returns {void}
 */
const DestroyToken = () => window.localStorage.removeItem(JWT_KEY)

/**
 * @function GetUserRoles
 * @description Retorna as roles do usuário logado.
 * @returns {Array<string>}
 */
export const GetUserRoles = () => {
  const rawRoles = get(GetTokenPayload(), 'role')
  if (!rawRoles) return []
  if (isString(rawRoles)) return [rawRoles]
  if (isArray(rawRoles)) return rawRoles
  return []
}

export const JWTService = {
  SaveToken,
  GetToken,
  GetTokenHeader,
  DestroyToken,
  GetTokenPayload,
  GetUserRoles,
}

export default JWTService
