import BatchStatusRoutes from './batch-status'
import ExportExcelRoutes from './export-excel'
import BatchSmsRoutes from './batch-sms'
import MeetingStudentRoutes from './meeting-student'
import EmployeeAndSingleQRCode from './employee-single-qrcode'
import WebForm from './web-form'

const ToolsRoutes = [
  ...BatchStatusRoutes,
  ...ExportExcelRoutes,
  ...BatchSmsRoutes,
  ...MeetingStudentRoutes,
  ...EmployeeAndSingleQRCode,
  ...WebForm
]

export default ToolsRoutes
