import AssertNotLoggedIn from '@/router/middlewares/AssertNotLoggedIn'
import AuthLayout from '@/layouts/Auth'

const PublicRoutes = [
  {
    path: '/abda',
    component: AuthLayout,
    beforeEnter: AssertNotLoggedIn,
    children: [
      {
        path: '/abda/login',
        name: 'SignIn',
        meta: {
          title: 'Login',
        },
        component: () => import('@/views/system/login'),
      },
      {
        path: '/abda/recuperar-senha',
        name: 'ForgotPassword',
        meta: {
          title: 'Esqueceu a senha',
        },
        component: () => import('@/views/system/forgot-password'),
      },
      {
        path: '/abda/redefinir-senha/:hash/:id',
        meta: {
          title: 'Redefinir senha',
        },
        component: () => import('@/views/system/reset-password'),
      },
      {
        path: '/abda/lockscreen',
        meta: {
          title: 'Lockscreen',
        },
        component: () => import('@/views/system/lockscreen'),
      },
      {
        path: '/abda/404',
        meta: {
          title: 'Página não encontrada',
        },
        component: () => import('@/views/system/404'),
      },
      {
        path: '/abda/500',
        meta: {
          title: 'Error 500',
        },
        component: () => import('@/views/system/500'),
      }
    ]
  }
]

export default PublicRoutes