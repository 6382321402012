const GymRating = [
  {
    path: '/academia',
    meta: { title: 'Academia' },
    name: 'gymRating',
    component: () => import('@/views/gym-rating/list/GymRating-List.vue'),
  },
  {
    path: '/academia/temporada/nova',
    meta: { title: 'Nova Turma na Temporada' },
    name: 'NewGymRating',
    component: () => import('@/views/gym-rating/edit/GymRating-Edit.vue'),
  },
  {
    path: '/academia/temporada/Editar',
    meta: { title: 'Editar Turma na Temporada' },
    name: 'EditGymRating',
    component: () => import('@/views/gym-rating/edit/GymRating-Edit.vue'),
  }
]
export default GymRating
