const SHOW_LOADING = 'showLoading';
const SET_LOADING = 'setLoading';

export const mutationTypes = {
  SHOW_LOADING,
  SET_LOADING,
};

export const mutations = {

  /**
   * @description Exibe carregando, acrescendo o counter em 1.
   */
  [SHOW_LOADING](state, payload) {
    state.loadingCount += (payload ? +1 : -1);
    state.isLoading = state.loadingCount > 0;
  },

  /**
   * @description Força o loader a ser exibido.
   */
  [SET_LOADING](state, payload) {
    state.isLoading = payload;
  },
};
