const SchoolRoutes = [
  {
    path: '/cadastros/escolas',
    meta: { title: 'Escolas' },
    name: 'School',
    component: () => import('@/views/configuration/school/list/SchoolList.vue')
  },
  {
    path: '/cadastros/escolas/novo',
    meta: { title: 'Nova Escola' },
    name: 'NewSchool',
    component: () => import('@/views/configuration/school/create/SchoolCreate.vue')
  },
  {
    path: '/cadastros/escolas/:id',
    meta: { title: 'Editar Escola' },
    name: 'EditSchool',
    component: () => import('@/views/configuration/school/edit/SchoolEdit.vue')
  }
]

export default SchoolRoutes
