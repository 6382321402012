const TeacherRoutes = [
  {
    path: '/professores/fluxograma',
    meta: { title: 'Fluxograma' },
    name: 'teacher',
    component: () => import('@/views/teacher/flow-chart/flowChart.vue')
  }
]

export default TeacherRoutes
