import TeacherRepository from '@/shared/http/repositories/socialProject/teacher'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import * as mutation from './mutationTypes'
import { actionTypes as action } from './actionTypes'

export const actions = {
  [action.FETCH_TEACHERS]: async ({ commit }) => {
    try {
      loading.push()
      let res = await TeacherRepository.GetAll()
      if (!IsSuccessCode(res)) Promise.reject()

      commit(mutation.SET_TEACHERS, res.data.data.sort((a, b) => {
        if (a.name > b.name) return 1

        if (a.name < b.name) return -1

        return 0
      }))
      loading.pop()
      return Promise.resolve()
    } catch (error) {
      loading.pop()
      toast.error('Erro ao carregar professores', 'ERRO')
    }
  }
}
