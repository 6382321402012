import SocialProjectManager from '../../clients/abdaSocialProjectManager'
import { merge } from 'lodash'

const resourceName = 'category'

const GetAll = (name, pagination) => SocialProjectManager.get(resourceName, { 
  params: merge(
    { name: name }, 
     pagination) 
  })
const GetAllByModality = modalityid => SocialProjectManager.get(`${resourceName}/${modalityid}/modality`)
const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)
const GetNextCategory = categoryid => SocialProjectManager.get(`${resourceName}/${categoryid}/next`)

const Create = data => SocialProjectManager.post(resourceName, data)

const Update = data => SocialProjectManager.put(`${resourceName}/${data.id}`, data)

const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)

const CategoryRepository = {
  GetAll,
  GetAllByModality,
  GetById,
  GetNextCategory,
  Create,
  Update,
  Delete
}

export default CategoryRepository
