export const globalGetters = {
  classes: 'class/classes',
  classe: 'class/classe',
  pagination: 'class/pagination'
}

export const getters = {
  classes: state => state.classes,
  classe: state => state.classe,
  pagination: state => state.pagination
}
