import StudentRepository from '@/shared/http/repositories/socialProject/student'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import * as mutation from './mutationTypes'
import { actionTypes as action } from './actionTypes'

export const actions = {
  [action.FETCH_STUDENTS]: async ({ dispatch }, { criteria, pagination }) => {
    try {
      loading.push()

      const res = await StudentRepository.GetAll(criteria, pagination)
      if (!IsSuccessCode(res)) return Promise.reject()

      dispatch('setStudents', res.data.data)
      dispatch('setPagination', res.data)

      loading.pop()
      return Promise.resolve()
    } catch (error) {
      loading.pop()
      toast.error('Erro ao carregar alunos', 'ERRO')
    }
  },

  [action.SET_STUDENTS]: ({ commit }, students) => {
    commit(mutation.SET_STUDENTS, students)
  },

  [action.SET_PAGINATION]: ({ commit }, { count, pagingLimit, pagingIndex }) => {
    commit(mutation.SET_PAGINATION, { count, pagingLimit, pagingIndex })
  },

  [action.SET_STUDENT]: ({ commit }, student) => {
    commit(mutation.SET_STUDENT, student)
  }
}
