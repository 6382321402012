import ModalityLocalTrainingRepository from '@/shared/http/repositories/socialProject/modalitylocaltraining'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import * as mutation from './mutationTypes'
import { actionTypes as action } from './actionTypes'

export const actions = {
  [action.FETCH_MODALITY_LOCAL_TRAINING]: async ({ commit }) => {
    try {
      loading.push()

      const res = await ModalityLocalTrainingRepository.GetAll()
      if (!IsSuccessCode(res)) Promise.reject()

      commit(mutation.SET_MODALITIES_LOCAL_TRAININGS, res.data.data)
      loading.pop()
      return Promise.resolve()
    } catch (error) {
      loading.pop()
      toast.error('Não foi possível carregar a lista de Modalidades e Locais de treinamento.', 'ERRO')
    }
  },

  [action.ON_SELECT_LOCAL_TRAINING]({ state, commit }) {
    let list = state.filteredArray

    if (state.modalityLocalTraining.localTrainingId) {
      const type = state.localTrainings.filter(lt => lt.localTrainingId === state.modalityLocalTraining.localTrainingId)

      if (type.length > 0) {
        state.modalityLocalTraining.localTrainingName = type[0].localTrainingName
        list = state.filteredArray.filter(lt => lt.localTrainingId === type[0].localTrainingId)
      }
    }

    commit(mutation.CLEAR_MODALITIES)
    for (let i = 0; i < list.length; i++) {
      let modality = {
        modalityId: list[i].modalityId,
        modalityName: list[i].modalityName
      }

      if (state.modalities.filter(m => m.modalityId === modality.modalityId).length === 0) {
        commit(mutation.SET_MODALITIES, modality)
      }
    }
  },

  [action.ON_SELECT_MODALITY]({ state, commit }) {
    let list = state.filteredArray

    if (state.modalityLocalTraining.modalityId) {
      let type = state.modalities.filter(m => m.modalityId === state.modalityLocalTraining.modalityId)

      if (type.length > 0) {
        state.modalityLocalTraining.modalityName = type[0].modalityName
        list = state.filteredArray.filter(m => m.modalityId === type[0].modalityId)
      }
    }

    commit(mutation.CLEAR_LOCAL_TRAININGS)
    for (let i = 0; i < list.length; i++) {
      let local = {
        localTrainingId: list[i].localTrainingId,
        localTrainingName: list[i].localTrainingName
      }

      if (state.localTrainings.filter(lt => lt.localTrainingId === local.localTrainingId).length === 0) {
        commit(mutation.SET_LOCAL_TRAININGS, local)
      }
    }
  }
}
