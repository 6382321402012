import AccessManager from '../../clients/abdaAccessManager'

const resourceName = 'Authenticate'

const RefreshToken = () => AccessManager.post(`${resourceName}/Refresh`)
const SignIn = (data = {}, config = {}) => AccessManager.post(`${resourceName}`, data, config)
const SignUp = (data = {}, config = {}) => {
  const sendData = Object.assign({
    CompanyId: process.env.CompanyId,
  }, data)

  return AccessManager.post(`${resourceName}/code`, sendData, config)
}
const SignOut = (data = {}, config = {}) => AccessManager.post(`${resourceName}/signOut`, data, config)

const AuthRepository = {
  SignIn,
  SignOut,
  SignUp,
  RefreshToken,
}

export default AuthRepository
