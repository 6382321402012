import WaitingList from './waitingList'
import WaitingListResultTest from './waitingListResultTest'
import WaitingListWeb from './waitingList-web'
import WaitingListGraphic from './waitingList-graphic'

const WaitingListRoutes = [
  ...WaitingList,
  ...WaitingListResultTest,
  ...WaitingListWeb,
  ...WaitingListGraphic
]

export default WaitingListRoutes
