import AccountRepository from '@/shared/http/repositories/access/account';
import { toast, loading } from '@/shared/utils/UIHelper';
import AuthService from '@/shared/services/auth';
import { JWTService } from '@/shared/services/jwt';
import { IsSuccessCode } from '@/shared/utils/API';
import { mutationTypes as mutation } from './mutations';

const SIGN_IN = 'signIn';
const SIGN_OUT = 'signOut';
const SIGN_UP = 'signUp';
const FETCH_USER_INFO = 'fetchUserInfo';

export const actionTypes = {
  SIGN_IN,
  SIGN_OUT,
  SIGN_UP,
  FETCH_USER_INFO,
};

export const globalActions = {
  SIGN_IN: `auth/${SIGN_IN}`,
  SIGN_OUT: `auth/${SIGN_OUT}`,
  SIGN_UP: `auth/${SIGN_UP}`,
  FETCH_USER_INFO: `auth/${FETCH_USER_INFO}`,
};

export const actions = {
  async [SIGN_IN]({ commit }, credentials) {
    await AuthService.signIn(credentials)
      .then((res) => {
        if (res.success) {
          commit(mutation.SET_JWT, JWTService.GetToken());
          commit(mutation.SET_USER_IS_LOGGED_IN, true);
          return Promise.resolve({ success: true });
        }

        JWTService.DestroyToken();
        commit(mutation.SET_JWT, null);
        commit(mutation.SET_USER_IS_LOGGED_IN, false);

        return Promise.reject({ success: false });
      })
      .catch(err => Promise.reject(err));
  },

  [SIGN_OUT]({ commit }, router) {
    AuthService.signOut();
    commit(mutation.SET_USER_IS_LOGGED_IN, false);
    commit(mutation.SET_JWT, null);
    commit(mutation.SET_USER_INFO, null);
    router.replace({ name: 'SignIn' });
  },

  async [FETCH_USER_INFO]({ state, commit }, force) {
    if ((force || !state.userInfo) && JWTService.GetToken()) {
      loading.push();
      await AccountRepository.Get(JWTService.GetTokenPayload().AccountID)
        .then((res) => {
          if (!IsSuccessCode(res)) return Promise.reject();
          commit(mutation.SET_USER_INFO, res.data.data);
          loading.pop();
          return Promise.resolve();
        })
        .catch(() => {
          toast.error('Erro ao obter informações do usuário.')
          loading.pop();
        });
    }
  },

};

export const getters = {
  userInfo: state => state.userInfo,
  jwt: state => state.jwt,
  userIsLoggedIn: state => state.userIsLoggedIn,
};

const SET_USER_INFO = 'setUserInfo';
const SET_USER_IS_LOGGED_IN = 'setUserIsLoggedIn';
const SET_JWT = 'setJWT';

export const mutationTypes = {
  SET_USER_INFO,
  SET_USER_IS_LOGGED_IN,
  SET_JWT,
};

export const mutations = {
  [SET_USER_INFO](state, payload) {
    state.userInfo = payload;
  },

  [SET_USER_IS_LOGGED_IN](state, payload) {
    state.userIsLoggedIn = payload;
  },

  [SET_JWT](state, payload) {
    state.jwt = payload;
  },
};

export const state = {
  userInfo: null,
  jwt: null,
  userIsLoggedIn: false,
};

export const MODULE_NAME = 'auth';

export const AuthModule = {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};

export default AuthModule;
