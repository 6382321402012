export const getters = {
  locale: state => state.locale,
  isSidebarOpen: state => state.isSidebarOpen,
  isSupportChatOpen: state => state.isSupportChatOpen,
  isMobileView: state => state.isMobileView,
  isTabletView: state => state.isTabletView,
  isMobileMenuOpen: state => state.isMobileView,
  isMenuCollapsed: state => state.isMenuCollapsed,
  isMenuShadow: state => state.isMenuShadow,
  isMenuUnfixed: state => state.isMenuUnfixed,
  menuLayoutType: state => state.menuLayoutType, // left <= , top, top-dark, nomenu
  menuType: state => state.menuType, // default, flyout, compact
  menuColor: state => state.menuColor, // dark, blue, gray, white
  flyoutMenuColor: state => state.flyoutMenuColor, // blue, blue, gray, white
  systemLayoutColor: state => state.systemLayoutColor, // gray, dark, blue, gray, image
  isTopbarFixed: state => state.isTopbarFixed,
  isFooterDark: state => state.isFooterDark,
  isContentNoMaxWidth: state => state.isContentNoMaxWidth,
  isAppMaxWidth: state => state.isAppMaxWidth,
  isGrayBackground: state => state.isGrayBackground,
  isGrayTopbar: state => state.isGrayTopbar,
  isCardShadow: state => state.isCardShadow,
  isSquaredBorders: state => state.isSquaredBorders,
  isBorderless: state => state.isBorderless,
  routerAnimation: state => state.routerAnimation, // 'slide-fadein-up', // 
}

export default getters