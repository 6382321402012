const ExportExcelRoutes = [
  {
    path: '/ferramentas/exportar-excel',
    meta: { title: 'Exportar em excel' },
    name: 'ExportExcel',
    component: () => import('@/views/tools/export-excel/ExportExcel.vue')
  }
]

export default ExportExcelRoutes
