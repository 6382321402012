const ClassRoutes = [
  {
    path: '/turmas',
    meta: { title: 'Turmas' },
    name: 'Class',
    component: () => import('@/views/class/Class.vue')
  }
]

export default ClassRoutes
