import { JWTService } from '@/shared/services/jwt'

/**
 * @function AssertLoggedIn
 * @description Middleware para ser executado antes da troca de rota para garantir
 * que os usuários estão autenticados para ver esta rota.
 * @param {Object} to A rota de onde o usuário está vindo.
 * @param {Object} from A rota que o usuário deseja acessar.
 * @param {Object} next A rota que será renderizada.
 */
const AssertLoggedIn = (to, from, next) => {
  if (!JWTService.GetToken()) {
    next({ name: 'SignIn' })
    return
  }

  next()
}

export default AssertLoggedIn
