var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{class:{
    [_vm.styles.air__menuTop__item]: true,
    [_vm.styles.air__menuTop__submenu]: true,
    [_vm.styles.air__menuTop__submenu__active]: _vm.item.key === _vm.activeSubmenu,
  }},[_c('a',{class:_vm.styles.air__menuTop__link,attrs:{"href":"javascript: void(0);"},on:{"click":() => _vm.handleSubmenuClick(_vm.item.key),"mouseenter":(event) => _vm.handleFlyoutOver(event, _vm.item.key, _vm.item.children),"mouseleave":function($event){return _vm.handleFlyoutOut(_vm.item.key)}}},[_c('i',{class:{
        [_vm.item.icon]: true,
        [_vm.styles.air__menuTop__icon]: true,
      }}),_c('span',[_vm._v(_vm._s(_vm.item.title))])]),_c('ul',{class:_vm.styles.air__menuTop__list},[_vm._l((_vm.item.children),function(item,index){return [(!item.children && !item.category)?_c('item',{key:index,attrs:{"item":item,"styles":_vm.styles,"activeItem":_vm.activeItem}}):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }