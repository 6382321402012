import en from './en.json';
import es from './es.json';
import ptBr from './pt-br.json';

const Languages = {
  en,
  es,
  ptBr,
};

export default Languages;
