const AttachmentTypeRoutes = [
  {
    path: '/cadastros/tipos-de-anexo',
    meta: { title: 'Tipos de Anexo' },
    name: 'AttachmentType',
    component: () => import('@/views/configuration/attachmenttype/list/AttachmentTypeList.vue')
  },
  {
    path: '/cadastros/tipos-de-anexo/novo',
    meta: { title: 'Novo Tipo de Anexo' },
    name: 'NewAttachmentType',
    component: () => import('@/views/configuration/attachmenttype/create/AttachmentTypeCreate.vue')
  },
  {
    path: '/cadastros/tipos-de-anexo/:id',
    meta: { title: 'Editar Tipo de Anexo' },
    name: 'EditAttachmentType',
    component: () => import('@/views/configuration/attachmenttype/edit/AttachmentTypeEdit.vue')
  }
]

export default AttachmentTypeRoutes
