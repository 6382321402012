<template>
  <div id="app">
    <loading v-show="isLoading" />
    <localization></localization>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Localization from '@/localization'
import { globalActions as authActions } from '@/store/modules/auth/actionTypes'
import { globalActions as settings } from '@/store/modules/settings/actions'
import Loading from '@/components/ui/Loading/loading.vue'

export default {
  name: 'app',
  components: { Localization, Loading },
  computed: {
    // ...mapGetters(['user']),
    ...mapGetters({
      isLoading: 'common/isLoading',
    }),
    nextRoute() {
      return this.$route.query.redirect || '/'
    },
  },
  watch: {
    user(auth) {
      if (auth) {
        this.$router.replace(this.nextRoute)
      }
    },
    '$route'(to) {
      const query = Object.assign({}, to.query)
      this.setupSettings(query)
    },
  },
  methods: {
    ...mapActions({
      setupSettings: settings.SETUP_URL_SETTINGS,
      fetchLoggedInUserInfo: authActions.FETCH_USER_INFO,
    })
  },
  mounted() {
    this.fetchLoggedInUserInfo();
  }
}
</script>
