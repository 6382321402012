import SchoolRepository from '@/shared/http/repositories/socialProject/school'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import * as mutation from './mutationTypes'
import { actionTypes as action } from './actionTypes'

export const actions = {
  [action.FETCH_SCHOOLS]: async ({ commit }) => {
    try {
      loading.push()

      const res = await SchoolRepository.GetAll()
      if (!IsSuccessCode(res)) return Promise.reject()

      commit(mutation.SET_SCHOOLS, res.data.data)
      loading.pop()
      return Promise.resolve()
    } catch (error) {
      loading.pop()
      toast.error('Erro ao carregar escolas', 'ERRO')
    }
  }
}
