import { capitalize } from 'lodash';

/**
 * Checa se uma string é um guid.
 * @param {string} str a string a ser testada.
 * @return {boolean}
 */
function isGuid(str) {
  const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  const result = guidRegex.test(str) || str === '00000000-0000-0000-0000-000000000000';
  return result;
}

/**
 * Deixa a primeira letra de cada palavra maiúscula.
 * @param {string} str a string de entrada.
 * @return {string}
 */
function capitalizeAll(str) {
  if (!str) {
    return '';
  }

  const words = str.split(/\s+/);
  const capitalizedWords = words.map(capitalize);
  const result = capitalizedWords.join(' ');

  return result;
}

export default {
  isGuid,
  capitalizeAll,
};
