const BenefitTypeRoutes = [
  {
    path: '/cadastros/tipos-de-beneficio',
    meta: { title: 'Tipos de Benefício' },
    name: 'BenefitType',
    component: () => import('@/views/configuration/benefittype/list/BenefitTypeList.vue')
  },
  {
    path: '/cadastros/tipos-de-beneficio/novo',
    meta: { title: 'Novo Tipo de Benefício' },
    name: 'NewBenefitType',
    component: () => import('@/views/configuration/benefittype/create/BenefitTypeCreate.vue')
  },
  {
    path: '/cadastros/tipos-de-beneficio/:id',
    meta: { title: 'Editar Tipo de Benefício' },
    name: 'EditBenefitType',
    component: () => import('@/views/configuration/benefittype/edit/BenefitTypeEdit.vue')
  }
]

export default BenefitTypeRoutes
