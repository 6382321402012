import {
  SET_CLASSES,
  SET_CLASS,
  SET_PAGINATION
} from './mutationTypes'
import Classe from '@/shared/models/class'

export const mutations = {
  [SET_CLASSES]: (state, payload) => state.classes = payload.map(classe => new Classe(classe)),

  [SET_CLASS]: (state, payload) => state.classe = new Classe(payload),

  [SET_PAGINATION]: (state, { count, pagingLimit, pagingIndex }) => {
    state.pagination = {
      count,
      limit: pagingLimit,
      index: pagingIndex
    }
  }
}
