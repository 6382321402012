import { mapGetters, mapActions } from 'vuex';
import SettingModule, { MODULE_NAME } from './index';

const SettingMixin = {
  namespaced: SettingModule.namespaced,
  computed: { ...mapGetters(MODULE_NAME, Object.keys(SettingModule.getters)) },
  methods: { ...mapActions(MODULE_NAME, Object.keys(SettingModule.actions)) },
};

export default SettingMixin;
