import SocialProjectManager from '../../clients/abdaSocialProjectManager'

const resourceName = 'cid'

const GetAll = () => SocialProjectManager.get(resourceName)
const GetAllCidCode = cidcode => SocialProjectManager.get(`${resourceName}/cidcode/${cidcode}`)
const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)
const GetByCidCode = cidCode => SocialProjectManager.get(`${resourceName}/${cidCode}`)

const CidRepository = {
  GetAll,
  GetById,
  GetByCidCode,
  GetAllCidCode
}

export default CidRepository