const GroupsRoutes = [
  {
    path: '/grupos',
    meta: { title: 'Grupos' },
    name: 'Groups',
    component: () => import('@/views/admin/groups/list/Groups.vue')
  },
  {
    path: '/grupos/novo',
    meta: { title: 'Novo Grupo' },
    name: 'NewGroup',
    component: () => import('@/views/admin/groups/edit/EditGroup.vue')
  },
  {
    path: '/grupos/:id',
    meta: { title: 'Editar Grupo' },
    name: 'EditGroup',
    component: () => import('@/views/admin/groups/edit/EditGroup.vue')
  }
]

export default GroupsRoutes
