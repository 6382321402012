 import { get, includes as anyIs } from 'lodash';
import StringUtils from '@/shared/utils/string';
import { JWTService } from '@/shared/services/jwt';
// import UserGroup from '@/shared/enums/userGroup';

const LOGGED_IN_USER = 'loggedInUser';
const LOGGED_IN_USER_IS_ADMIN = 'userIsAdmin';
const LOGGED_IN_USER_HAS_ADMINISTRATIVE_ROLE = 'userHasAdministrativeRole';

export const globalGetters = {
  LOGGED_IN_USER: `auth/${LOGGED_IN_USER}`,
  LOGGED_IN_USER_IS_ADMIN: `auth/${LOGGED_IN_USER_IS_ADMIN}`,
  LOGGED_IN_USER_HAS_ADMINISTRATIVE_ROLE: `auth/${LOGGED_IN_USER_HAS_ADMINISTRATIVE_ROLE}`,
};

export const getters = {
  userInfo: state => state.userInfo || {},

  userHasAdministrativeRole: (state, moduleGetters) => moduleGetters.loggedInUser.hasAdministrativeRole,

  loggedInUser: (state, moduleGetters) => ({
    name: StringUtils.capitalizeAll(get(state, 'userInfo.name', '-')),
    isAdmin: moduleGetters.userIsAdmin,
    email: get(state, 'userInfo.email', '-'),
    group: '',
    id: get(state, 'userInfo.id', '-'),
    hasAdministrativeRole: anyIs([
      moduleGetters.userIsAdmin,
    ], true),
  }),

  jwt: () => JWTService.GetToken(),

  userIsLoggedIn: state => state.userIsLoggedIn,
};

export default getters;
