const CategoryRoutes = [
  {
    path: '/cadastros/categorias',
    meta: { title: 'Categorias' },
    name: 'Category',
    component: () => import('@/views/configuration/category/list/CategoryList.vue')
  },
  {
    path: '/cadastros/categorias/novo',
    meta: { title: 'Nova Categoria' },
    name: 'NewCategory',
    component: () => import('@/views/configuration/category/create/CategoryCreate.vue')
  },
  {
    path: '/cadastros/categorias/:id',
    meta: { title: 'Editar Categoria' },
    name: 'EditCategory',
    component: () => import('@/views/configuration/category/edit/CategoryEdit.vue')
  }
]

export default CategoryRoutes
