export const globalGetters = {
  students: 'student/students',
  student: 'student/student',
  pagination: 'student/pagination'
}

export const getters = {
  students: state => state.students,
  student: state => state.student,
  pagination: state => state.pagination
}
