const MeetingStudentRoutes = [
  {
    path: '/ferramentas/reuniao-aluno',
    meta: { title: 'Reuniao dos alunos' },
    name: 'MeetingStudent',
    component: () => import('@/views/tools/meeting-student/MeetingStudent.vue')
  }
]

export default MeetingStudentRoutes
