import GuidUtils from '@/shared/utils/guid'
import statusEnum from '@/shared/enums/status'

export default class Student {
  constructor({
    id,
    companyId,
    socialEntityId,
    healthPlanId,
    code,
    name,
    gender,
    birthDate,
    documentBirth,
    documentRG,
    documentCPF,
    documentNIS,
    cityId,
    stateId,
    zipCode,
    address,
    number,
    complement,
    district,
    schoolId,
    familyIncome,
    status,
    contacts,
    registrations,
    yearReenrollment,
    deficiencyId,
    hasDeficiency,
    height,
    weight,
    link,
    cidCode,
    deficiencyTypeId,
    email
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.companyId = companyId
    this.socialEntityId = socialEntityId
    this.healthPlanId= healthPlanId
    this.code = code
    this.name = name
    this.gender = gender
    this.birthDate = birthDate
    this.documentBirth = documentBirth
    this.documentRG = documentRG
    this.documentCPF = documentCPF
    this.documentNIS = documentNIS
    this.cityId = cityId
    this.stateId = stateId
    this.zipCode = zipCode
    this.address = address
    this.number = number
    this.complement = complement
    this.district = district
    this.schoolId = schoolId
    this.familyIncome = familyIncome
    this.status = status
    this.statusText = this.getStatus(status)
    this.contacts = contacts ? contacts : []
    this.registrations = registrations ? registrations : []
    this.yearReenrollment = yearReenrollment,
    this.deficiencyId = deficiencyId,
    this.hasDeficiency = hasDeficiency,
    this.height = height,
    this.weight = weight,
    this.link = link
    this.cidCode = cidCode
    this.deficiencyTypeId = deficiencyTypeId
    this.email = email
  }

  getStatus(status){
    const statusObj = Object.values(statusEnum).find(element => element.id === status)

    if(!statusObj)
      return ''

    return statusObj.name
  }
}
