const BatchSmsRoutes = [
  {
    path: '/ferramentas/sms-em-lote',
    meta: { title: 'SMS em Lote' },
    name: 'BatchSms',
    component: () => import('@/views/tools/batch-sms/BatchSms.vue')
  }
]

export default BatchSmsRoutes
