import { mutationTypes as mutation } from './mutations'

const CHANGE_SETTING = 'changeSetting'
const SETUP_URL_SETTINGS = 'setupUrlSettings'

export const actionTypes = {
  CHANGE_SETTING,
  SETUP_URL_SETTINGS,
}

export const globalActions = {
  CHANGE_SETTING: `settings/${CHANGE_SETTING}`,
  SETUP_URL_SETTINGS: `settings/${SETUP_URL_SETTINGS}`,
}

export const actions = {
  [CHANGE_SETTING]({ commit }, payload) {
    commit(mutation.CHANGE_SETTING, payload)
  },

  [SETUP_URL_SETTINGS]({ commit }, payload) {
    commit(mutation.SETUP_URL_SETTINGS, payload)
  },
}