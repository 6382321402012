const TeacherRoutes = [
  {
    path: '/cadastros/professores',
    meta: { title: 'Professores' },
    name: 'Teacher',
    component: () => import('@/views/configuration/teacher/list/TeacherList.vue')
  },
  {
    path: '/cadastros/professores/novo',
    meta: { title: 'Novo Professor' },
    name: 'NewTeacher',
    component: () => import('@/views/configuration/teacher/create/TeacherCreate.vue')
  },
  {
    path: '/cadastros/professores/:id',
    meta: { title: 'Editar Professor' },
    name: 'EditTeacher',
    component: () => import('@/views/configuration/teacher/edit/TeacherEdit.vue')
  }
]

export default TeacherRoutes
