import GuidUtils from '@/shared/utils/guid'
import DaysWeek from '@/shared/enums/daysWeek'

export default class Class {
  constructor({
    id,
    name,
    modalityId,
    modalityName,
    companyId,
    localTrainingId,
    localTrainingName,
    categoryId,
    categoryName,
    modalityCategoryName,
    days,
    hours,
    classSchedule,
    classTeacher,
    dayWeek

  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.name = name,
    this.modalityId = modalityId,
    this.modalityName = modalityName,
    this.companyId = companyId,
    this.localTrainingId = localTrainingId,
    this.localTrainingName = localTrainingName,
    this.categoryId = categoryId,
    this.categoryName = categoryName,
    this.modalityCategoryName = modalityCategoryName,
    this.days = days,
    this.dayWeek = dayWeek,
    this.dayWeekName = this.getDaysWeek(classSchedule)
    this.hours = hours,
    this.classSchedule = classSchedule ? classSchedule : [],
    this.classTeacher = classTeacher ? classTeacher : []
  }
  
  getDaysWeek(classSchedule) {
    if(!classSchedule)
      return

    const daysWeek = Object.values(DaysWeek)

    const dayWeek = classSchedule.map(schedule => {
      const dayName = daysWeek.find(day => day.value === schedule.dayWeek)
      return dayName.text
    })

    return dayWeek.join(', ')
  }
}