const QrCodesRoutes = [
  {
    path: '/qrcode-list',
    meta: { title: 'QRCodes' },
    name: 'QRCodes',
    component: () => import('@/views/admin/qrcode-list/qrCode-list.vue')
  }
]

export default QrCodesRoutes
